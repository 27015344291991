import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { CatalogTabsInitialStateType, CatalogTabsType } from '../../../models/education/catalog-tabs-model';
import CatalogTabsService from '../../../services/education/catalog-tabs.service';

const initialState: CatalogTabsInitialStateType = {
  CatalogTabsData: [],
  CatalogTabsLoading: true,
  CatalogTabsError: undefined
};

type queryparamsType = {
  path: string
  pageNumber: number
  isSalePage?: boolean
}

export const retrieveCatalogTabs = createAsyncThunk('CatalogTabs/retrieve',
  async (queryparams: queryparamsType, { rejectWithValue }) => {
    try {

      const response = await CatalogTabsService.getCatalogTabs(queryparams.path, queryparams.pageNumber, queryparams.isSalePage ?? false)
      return [response.data]

    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.status)
    }
  }
)

const CatalogTabsSlice = createSlice({
  name: 'CatalogTabs',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCatalogTabs.pending, (state) => {
        state.CatalogTabsLoading = true;
      })
      .addCase(retrieveCatalogTabs.fulfilled, (state, action) => {
        if (action.payload) {
          state.CatalogTabsLoading = false;
          state.CatalogTabsData = action.payload as CatalogTabsType[];
        }
      })
      .addCase(retrieveCatalogTabs.rejected, (state, { payload }) => {
        state.CatalogTabsLoading = false;
        state.CatalogTabsError = apiErrorHandler(Number(payload))
      })
  }
})

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default CatalogTabsSlice.reducer;
