import React, { ReactNode } from "react"; 
import { Navigate } from "react-router-dom"; 

import PrivateRoute from "./routes/private-routes";
import LoggedInRedirectCheckComponent from "./pages/redirects/logged-in-redirect";

// General 
const Login = React.lazy(() => import("./pages/errors/login"));
const ExternalRedirectUrl = React.lazy(() => import("./pages/redirects/external-redirect"));
const PageNotFound = React.lazy(() => import("./pages/errors/page-not-found"));
const PageProhibited = React.lazy(() => import("./pages/errors/page-prohibited"));
const SystemTransition = React.lazy(() => import("./pages/errors/system-transition"));
// const RedirectUrl = React.lazy(() => import("./pages/redirects"));

// Custom Pages
const Home = React.lazy(() => import("./pages/home/home"));
const Search = React.lazy(() => import("./pages/search/search"));
const MembersDashboard = React.lazy(() => import("./pages/member-dashboard"));
const ProfessionalProfile = React.lazy(() => import("./pages/member-dashboard/professional-profile"));
const Preferences = React.lazy(() => import("./pages/preferences"));

// News and Articles
const NewsAndArticles = React.lazy(() => import("./pages/news-and-articles/news-and-articles"));
const EditorialCalender = React.lazy(() => import("./pages/news-and-articles/regulatory-focus/about/editorial-calender"));
const GuidelineToArticleTypes = React.lazy(() => import("./pages/news-and-articles/regulatory-focus/about/guideline-to-article-types"));
const AboutRegulatoryFocus = React.lazy(() => import("./pages/news-and-articles/regulatory-focus/about-regulatory-focus"));
const RFQuarterly = React.lazy(() => import("./pages/news-and-articles/rf-quarterly"));
const CopyrightClearanceCenter = React.lazy(() => import("./pages/news-and-articles/regulatory-focus/about/copyright-clearance-center"));
const EditorialAdvisoryCommittee = React.lazy(() => import("./pages/news-and-articles/editorial-advisory-committee/editorial-advisory-committee"));
const EditorialAdvisoryCommitteeGuidelines = React.lazy(() => import("./pages/news-and-articles/editorial-advisory-committee/editorial-advisory-committee-guidelines"));
const AuthorGuidelines = React.lazy(() => import("./pages/news-and-articles/regulatory-focus/about/author-guidelines"));
const NewsAndArticleList = React.lazy(() => import("./pages/news-and-articles/article/news-and-articles-list"));
const NewsAndArticleDetails = React.lazy(() => import("./pages/news-and-articles/news-articles"));
const NewsEditorialTeam = React.lazy(() => import("./pages/news-and-articles/news-editorial-team/news-editorial-team"));

// Who We Are
const WhoWeAre = React.lazy(() => import("./pages/who-we-are"));
const AboutTheRegulatoryProfession = React.lazy(() => import("./pages/who-we-are/about-the-regulatory-profession"));
const PositionDescriptionsAndDuties = React.lazy(() => import("./pages/who-we-are/board-of-directors/position-descriptions-and-duties"));
const NominationProcess = React.lazy(() => import("./pages/who-we-are/board-of-directors/nomination-process"));
const BoardOfDirectors = React.lazy(() => import("./pages/who-we-are/board-of-directors/raps-board-of-directors"));
const RapsEurope = React.lazy(() => import("./pages/who-we-are/raps-europe"));
const RapsEuropeRec = React.lazy(() => import("./pages/who-we-are/raps-europe/rec"));
const Bylaw = React.lazy(() => import("./pages/who-we-are/board-of-directors/raps-bylaws"));
const WorkForRaps = React.lazy(() => import("./pages/who-we-are/work-for-raps"));
const WorkForRapsSubpage = React.lazy(() => import("./pages/who-we-are/work-for-raps-subpage"));
const Governance = React.lazy(() => import("./pages/who-we-are/board-of-directors"));
const MeetTheRapsAwardees = React.lazy(() => import("./pages/who-we-are/celebrate-the-profession/meet-the-raps-awardees"));
const MeetTheRapsAwardeesProfile = React.lazy(() => import("./pages/who-we-are/celebrate-the-profession/meet-the-raps-awardees-profile-pages"));
const RapsEuropeBoard = React.lazy(() => import("./pages/who-we-are/raps-europe/raps-europe-board"));
const CallForAuthors = React.lazy(() => import("./pages/who-we-are/call-for-authors"));
const CelebrateTheProfession = React.lazy(() => import("./pages/who-we-are/celebrate-the-profession"));
const Awards = React.lazy(() => import("./pages/who-we-are/celebrate-the-profession/awards"));
const CodeOfEthics = React.lazy(() => import("./pages/who-we-are/code-of-ethics"));
const ContactUs = React.lazy(() => import("./pages/who-we-are/contact-us"));
const YearInReview = React.lazy(() => import("./pages/who-we-are/year-in-review"));
const PressRoom = React.lazy(() => import("./pages/who-we-are/press-room"));

// Resources
const RegulatoryCompetencyFramework = React.lazy(() => import("./pages/resources/regulatory-competency-framework"));
const ScopeOfPracticeSurvey = React.lazy(() => import("./pages/resources/scope-of-practice-survey"));
const WorkforceReport = React.lazy(() => import("./pages/resources/workforce"));
const SuppliersGuide = React.lazy(() => import("./pages/resources/suppliers-guide"));
const SuppliersGuideSubPage = React.lazy(() => import("./pages/resources/suppliers-guide/suppliers-guide-sub-page"));
const UniversityResources = React.lazy(() => import("./pages/resources/university-resources"));
const ProfessionalDevelopment = React.lazy(() => import("./pages/resources/professional-development"));

// RAC Pages
const RACPages = React.lazy(() => import("./pages/rac-credentials"));
// End RAC Pages

// Education
// Events
const Events = React.lazy(() => import("./pages/events/events"));
const EventDetails = React.lazy(() => import("./pages/events/event-details"));
const ActiveEvents = React.lazy(() => import("./pages/education/active-events"));
const VideoPage = React.lazy(() => import("./pages/education/video"));
const CorporateTraining = React.lazy(() => import("./pages/education/corporate-training"));
const WorkshopProposalRequest = React.lazy(() => import("./pages/education/in-person/workshop-proposal-request"));
const SubmitIdea = React.lazy(() => import("./pages/education/submit-idea"));
const EventRegistrationCancellationRequest = React.lazy(() => import("./pages/education/event-registration-cancellation-request"));
const WorkshopWeek = React.lazy(() => import("./pages/education/workshop-week"));
const EuropeanEvents = React.lazy(() => import("./pages/education/european-events"));
const ExecutiveDevelopment = React.lazy(() => import("./pages/education/executive-development"));
const EcornellLeadershipCertificate = React.lazy(() => import("./pages/education/ecornell-leadership-certificate"));
const CertificateInEffectiveCommunication = React.lazy(() => import("./pages/education/e-learning/certificate-in-effective-communication"));
const ConferenceSeries = React.lazy(() => import("./pages/education/conference-series"));
const ExhibitorSponsorships = React.lazy(() => import("./pages/education/exhibitor-sponsorships"));
const EventsCodeOfConductIncidentReport = React.lazy(() => import("./pages/education/event-code-of-conduct-incident-report"));
const TopicalSubPage = React.lazy(() => import("./pages/resources/topics/topic-items"));

//Products
const ProductDetails = React.lazy(() => import("./pages/products/product-details"));
const DiscoverProducts = React.lazy(() => import("./pages/education/discover-products"));
const OnlineUniversityCertificates = React.lazy(() => import("./pages/education/online-university-certificates"));
const CourseBundles = React.lazy(() => import("./pages/education/course-bundles"));
const Books = React.lazy(() => import("./pages/education/books"));
const OnlineUniversity = React.lazy(() => import("./pages/education/online-university"));
const Courses = React.lazy(() => import("./pages/education/courses"));
const BookSale = React.lazy(() => import("./pages/resources/book-sale"));
const ConvergenceStore = React.lazy(() => import("./pages/education/convergence-store"));
const BlackFriday = React.lazy(() => import("./pages/education/black-friday"));
const PageTabs = React.lazy(() => import("./pages/education/page-tabs"));
const PartnerShip = React.lazy(() => import("./pages/education/partnership"));
const ContentPreview = React.lazy(() => import("./pages/education/preview"));
const CventRegistration = React.lazy(() => import("./pages/education/cvent-registration"));

// Membership
const JoinRAPS = React.lazy(() => import("./pages/join-raps"));
const RenewMembership = React.lazy(() => import("./pages/renew-membership"));
const Welcometoraps = React.lazy(() => import("./pages/welcometoraps"));
const Student = React.lazy(() => import("./pages/membership/student"));
const EmergingMarketsCountries = React.lazy(() => import("./pages/membership/emerging-markets-countries"));
const ChaptersLocalNetworks = React.lazy(() => import("./pages/membership/chapters-local-networks"));
const EnterpriseMembers = React.lazy(() => import("./pages/membership/enterprise-members"));
const AcademicEnterprise = React.lazy(() => import("./pages/membership/academic-enterprise"));
const EnterpriseMembershipFAQs = React.lazy(() => import("./pages/membership/enterprise-members/enterprise-membership-faqs/enterprise-membership-faqs"));
const RapsFellows = React.lazy(() => import("./pages/membership/raps-fellows/raps-fellows"));
const MeetRapsFellows = React.lazy(() => import("./pages/membership/raps-fellows/meet-raps-fellows"));
const MembershipApply = React.lazy(() => import("./pages/membership/raps-fellows/apply"));
const VolunteerOpportunities = React.lazy(() => import("./pages/membership/volunteer-opportunities"));
const EnterpriseMembershipInquiry = React.lazy(() => import("./pages/membership/enterprise-members/enterprise-membership-inquiry/enterprise-membership-inquiry"));
const EnterpriseMembershipActivation = React.lazy(() => import("./pages/membership/enterprise-members/activation"));
const PowerOfOne = React.lazy(() => import("./pages/membership/power-of-one"));
const SuccessPartners = React.lazy(() => import("./pages/membership/success-partners"));
const Mentorship = React.lazy(() => import("./pages/membership/mentorship"));
const Hardship = React.lazy(() => import("./pages/membership/hardship"));

// Careers
const CareerHome = React.lazy(() => import("./pages/careers/career-home"));
const CareerStudent = React.lazy(() => import("./pages/careers/career-student"));
const CareerAcademic = React.lazy(() => import("./pages/careers/career-academic"));
const CareerFair = React.lazy(() => import("./pages/careers/career-fair"));
const AcademicPrograms = React.lazy(() => import("./pages/careers/academic-programs"));

// Website
const TermsOfUse = React.lazy(() => import("./pages/website/terms-of-use"));
const StyleGuide = React.lazy(() => import("./pages/website/style-guide"));
const PrivacyPolicy = React.lazy(() => import("./pages/website/privacy-policy"));
const VirtualEventHelp = React.lazy(() => import("./pages/website/virtual-event-help"));
const WebSiteFAQsPage = React.lazy(() => import("./pages/website/website-faqs"));

// Form Embeds
const FormEmbeds = React.lazy(() => import("./pages/form-embeds"));
// End of Form Embeds


export type route = {
  component: ReactNode;
  exact: boolean | undefined;
  name: string | undefined;
  path: string;
  routes?: route[];
};

const AppRouter: route[] = [
  { path: "/", exact: true, name: "Home", component: <Home /> },
  { path: "/home", exact: true, name: "", component: <Navigate to={`/${window.location.search}`} replace /> },
  { path: "/who-we-are", exact: true, name: "Who We Are", component: <WhoWeAre /> },
  { path: "/search", exact: true, name: "search", component: <Search /> },
  { path: "/preferences", exact: true, name: "Communication Preferences", component: <Preferences /> },
  { path: "/member-dashboard", exact: true, name: "Member's Dashboard", component: (<PrivateRoute> <MembersDashboard /> </PrivateRoute>) },
  { path: "/member-dashboard/professional-profile", exact: true, name: "Professional Profile", component: (<PrivateRoute> <ProfessionalProfile /> </PrivateRoute>) },

  //{ path: '/404', exact: true, name: "404", component: <PageNotFound /> },
  { path: "/login/prohibited", exact: true, name: "", component: <Navigate to={`/prohibited${window.location.search}`} replace /> },
  { path: "/prohibited", exact: true, name: "Prohibited", component: <PageProhibited /> },
  { path: '/transition', exact: true, name: "System Transition", component: <SystemTransition /> }, 
  { path: "/login", exact: true, name: "Login", component: <LoggedInRedirectCheckComponent><Login /></LoggedInRedirectCheckComponent> },
  { path: "/*", exact: true, name: "404", component: <PageNotFound /> },

  { path: "/news-and-articles", exact: true, name: "Regulatory Focus™ ", component: <NewsAndArticles /> },
  { path: "/news-and-articles/about-regulatory-focus", exact: true, name: "About Regulatory Focus", component: <AboutRegulatoryFocus /> },
  { path: "/news-and-articles/about-regulatory-focus/editorial-calendar", exact: true, name: "Editorial Calendar", component: <EditorialCalender /> },
  { path: "/news-and-articles/about-regulatory-focus/guideline-to-article-types", exact: true, name: "Guideline To Article Types", component: <GuidelineToArticleTypes /> },
  { path: "/news-and-articles/copyright-clearance-center", exact: true, name: "Copyright & Terms of Use", component: <CopyrightClearanceCenter /> },
  { path: "/news-and-articles/about-regulatory-focus/author-guidelines", exact: true, name: "Author Guidelines", component: <AuthorGuidelines /> },
  { path: "/news-and-articles/about-regulatory-focus/news-editorial-team", exact: true, name: "News Editorial Team", component: <NewsEditorialTeam /> }, 
  { path: "/news-and-articles/compendia", exact: true, name: "RF Quarterly", component: <RFQuarterly /> },
  { path: "/news-and-articles/rf-quarterly/*", exact: true, name: "RF Quarterly", component: <RFQuarterly /> },
  { path: "/news-and-articles/compendia/*", exact: true, name: "RF Quarterly", component: <RFQuarterly /> },
  { path: "/news-and-articles/editorial-advisory-committee", exact: true, name: "Author Guidelines", component: <EditorialAdvisoryCommittee /> },
  { path: "/news-and-articles/editorial-advisory-committee/editorial-advisory-committee-guidelines", exact: true, name: "Author Guidelines", component: <EditorialAdvisoryCommitteeGuidelines /> },
  { path: "/news-and-articles/news-articles", exact: true, name: "News Search", component: <NewsAndArticleList /> },
  { path: "/news-and-articles/news-articles/*", exact: true, name: "News Articles", component: <NewsAndArticleDetails /> },

  { path: "/who-we-are/raps-europe", exact: true, name: "RAPS Europe", component: <RapsEurope /> },
  { path: "/who-we-are/raps-europe/rec", exact: true, name: "RAPS Europe REC", component: <RapsEuropeRec /> },
  { path: "/who-we-are/board-of-directors/raps-board-of-directors", exact: true, name: "Board of Directors", component: <BoardOfDirectors /> },
  { path: "/who-we-are/board-of-directors/board-of-directors-position-descriptions-and-dutie", exact: true, name: "Position Descriptions and Duties", component: <PositionDescriptionsAndDuties /> },
  { path: "/who-we-are/board-of-directors/nomination-process", exact: true, name: "Nomination Process", component: <NominationProcess /> },
  { path: "/who-we-are/about-the-regulatory-profession", exact: true, name: "About Regulatory", component: <AboutTheRegulatoryProfession /> },
  { path: "/who-we-are/call-for-authors", exact: true, name: "Call For Authors", component: <CallForAuthors /> },
  { path: "/who-we-are/celebrate-the-profession", exact: true, name: "Celebrate The Profession", component: <CelebrateTheProfession /> },
  { path: "/who-we-are/celebrate-the-profession/awards", exact: true, name: "Awards", component: <Awards /> },
  { path: "/who-we-are/celebrate-the-profession/fellows-applications-and-selection-process", exact: true, name: "Fellows Apply", component: <Navigate to={`/fellows/apply${window.location.search}`} replace /> },
  { path: "/who-we-are/celebrate-the-profession/meet-raps-fellows", exact: true, name: "Login", component: <Navigate to={`/membership/raps-fellows${window.location.search}`} replace /> },
  { path: "/who-we-are/code-of-ethics", exact: true, name: "Login", component: <CodeOfEthics /> },
  { path: "/who-we-are/advancing-the-profession/code-of-ethics", exact: true, name: "Login", component: <CodeOfEthics /> },
  { path: "/who-we-are/contact-us", exact: true, name: "Login", component: <ContactUs /> },
  { path: "/who-we-are/board-of-directors/raps-bylaws", exact: true, name: "Bylaws", component: <Bylaw /> },
  { path: "/who-we-are/governance/raps-bylaws", exact: true, name: "By Laws", component: <Bylaw /> },
  { path: "/who-we-are/year-in-review", exact: true, name: "Login", component: <YearInReview /> },
  { path: "/who-we-are/press-room", exact: true, name: "Login", component: <PressRoom /> },
  { path: "/who-we-are/work-for-raps", exact: true, name: "Work for RAPS", component: <WorkForRaps /> },
  { path: "/who-we-are/work-for-raps/:pageName", exact: true, name: "Work for RAPS", component: <WorkForRapsSubpage /> },
  { path: "/who-we-are/board-of-directors", exact: true, name: "Governance", component: <Governance /> },
  { path: "/who-we-are/celebrate-the-profession/meet-the-raps-awardees", exact: true, name: "Meet The Raps Awardees", component: <MeetTheRapsAwardees /> },
  { path: "/who-we-are/celebrate-the-profession/meet-the-raps-awardees/*", exact: true, name: "Meet The Raps Awardees Profile", component: <MeetTheRapsAwardeesProfile /> },
  { path: "/who-we-are/raps-europe/raps-europe-board", exact: true, name: "Meet The Raps Awardees", component: <RapsEuropeBoard /> },

  { path: "/welcometoraps", exact: true, name: "Onboarding", component: <Welcometoraps /> },
  { path: "/renew-membership", exact: true, name: "Renew Membership", component: <RenewMembership /> },
  { path: "/join", exact: true, name: "Join", component: <Navigate to={`/join-raps${window.location.search}`} replace /> },
  { path: "/join-raps", exact: true, name: "Join RAPS", component: <JoinRAPS /> },
  { path: "/membership/enterprise-members", exact: true, name: "Enterprise Membership", component: <EnterpriseMembers /> },
  { path: "/membership/enterprise-members-inquiry", exact: true, name: "Enterprise Membership Inquiry", component: <EnterpriseMembershipInquiry /> },
  { path: "/membership/enterprise-members/enterprise-membership-faqs", exact: true, name: "Enterprise Membership FAQs", component: <EnterpriseMembershipFAQs /> },
  { path: "/membership/academic-enterprise", exact: true, name: "Academic Enterprise", component: <AcademicEnterprise /> },
  { path: "/membership/raps-fellows", exact: true, name: "RAPS Fellows", component: <RapsFellows /> },
  { path: "/membership/enterprise-members/activation", exact: true, name: "Activation", component: <EnterpriseMembershipActivation /> },
  { path: "/membership/chapters-local-networks", exact: true, name: "Chapters & Local Networks", component: <ChaptersLocalNetworks /> },
  { path: "/membership-community/chapters-local-networks", exact: true, name: "Chapters & Local Networks", component: <ChaptersLocalNetworks /> },
  { path: "/membership/raps-fellows/fellows-applications-and-selection-process", exact: true, name: "Apply", component: <MembershipApply /> },
  { path: "/membership/volunteer-opportunities", exact: true, name: "Volunteer Opportunities", component: <VolunteerOpportunities /> },
  { path: "/membership/success-partners", exact: true, name: "Success Partners", component: <SuccessPartners /> }, 
  { path: "/membership/mentorship", exact: true, name: "Mentorship Program", component: <Mentorship /> }, 
  { path: "/membership/student", exact: true, name: "Student", component: <Student /> },
  { path: "/membership/power-of-one", exact: true, name: "Power Of One", component: <PowerOfOne /> },
  { path: "/membership/emerging-markets-countries", exact: true, name: "", component: <EmergingMarketsCountries /> },
  { path: "/membership/hardship", exact: true, name: "Hardship Dues Program", component: <Hardship /> },
  { path: "/fellows/meet-raps-fellows", exact: true, name: "Meet the Fellows", component: <MeetRapsFellows /> },
  { path: "/fellows/apply", exact: true, name: "Fellows Apply", component: <MembershipApply /> },

  //form embeds
  { path: "/form-embeds/enterprise-members-inquiry", exact: true, name: "Enterprise Members Inquiry", component: <FormEmbeds /> },
  { path: "/form-embeds/enterprise-membership-inquiry", exact: true, name: "Enterprise Membership Inquiry", component: <FormEmbeds /> },
  { path: "/form-embeds/on-site-training", exact: true, name: "On Site Training", component: <FormEmbeds /> },
  { path: "/form-embeds/group-rates", exact: true, name: "Group Rates", component: <FormEmbeds /> },
  { path: "/form-embeds/online-learning-group-rates", exact: true, name: "Online Learning Group Rates", component: <FormEmbeds /> },
  { path: "/form-embeds/confirmation", exact: true, name: "Confirmation", component: <FormEmbeds /> },

  { path: "/resources/regulatory-competency-framework", exact: true, name: "Regulatory Competency Framework", component: <RegulatoryCompetencyFramework /> },
  { path: "/resources/scope-of-practice-survey", exact: true, name: "Scope of Practice Survey", component: <ScopeOfPracticeSurvey /> },
  { path: "/careers/scope-of-practice-survey", exact: true, name: "Scope of Practice Survey", component: <ScopeOfPracticeSurvey /> },
  { path: "/resources/workforce-report", exact: true, name: "Workforce Report", component: <WorkforceReport /> }, 
  { path: "/resources/book-sale", exact: true, name: "Book Sale", component: <BookSale /> },
  { path: "/resources/suppliers-guide", exact: true, name: "Suppliers Guide", component: <SuppliersGuide /> },
  { path: "/publications-resources/suppliers-guide/:pageName", exact: true, name: "Suppliers Guide", component: <SuppliersGuideSubPage /> },
  { path: "/resources/suppliers-guide/:pageName", exact: true, name: "Suppliers Guide", component: <SuppliersGuideSubPage /> },
  { path: "/resources/university-resources", exact: true, name: "University Resources", component: <UniversityResources /> },
  { path: "/resources/professional-development", exact: true, name: "Professional Development", component: <ProfessionalDevelopment /> },

  { path: "/careers", exact: true, name: "Career Home", component: <CareerHome /> },
  { path: "/careers/academic-programs", exact: true, name: "Academic Programs", component: <AcademicPrograms /> },
  { path: "/careers/student", exact: true, name: "Career Home", component: <CareerStudent /> },
  { path: "/careers/academic", exact: true, name: "Career Home", component: <CareerAcademic /> },
  { path: "/careers/career-fair", exact: true, name: "Career Fair", component: <CareerFair /> },

  // RAC Routes
  { path: "/rac-credential/*", exact: true, name: "RAC Credential Home", component: <RACPages /> },
  // End RAC Routes

  // Education
  { path: "/education/convergence-store", exact: true, name: "Convergence Store", component: <ConvergenceStore /> },
  { path: "/education/online-university-certificates", exact: true, name: "Online University Certificates", component: <OnlineUniversityCertificates /> },
  { path: "/education/course-bundles", exact: true, name: "Course Bundles", component: <CourseBundles /> },
  { path: "/education/e-learning/course-bundles", exact: true, name: "Course Bundles", component: <CourseBundles /> },
  { path: "/events", exact: true, name: "Events", component: <Events /> },
  { path: "/events/:path", exact: true, name: "Event Details", component: <EventDetails /> },
  { path: "/products/:path", exact: true, name: "Product Details", component: <ProductDetails /> },
  { path: "/education-events/e-learning/online-university-certificates/the-regulatory-affairs-certificate-pharmaceutical",
    exact: true, name: "Certificate Pharmaceutical",
    component: <Navigate to={`/products/regulatory-affairs-certificate-pharmaceuticals${window.location.search}`} replace /> },
  { path: "/education-events/e-learning/online-university-certificates/the-regulatory-affairs-certificate-medical-device",
    exact: true, name: "Certificate Medical Device",
    component: <Navigate to={`/products/regulatory-affairs-certificate-medical-devices-and-pharmaceuticals-dual${window.location.search}`} replace /> },
  { path: "/education-events/e-learning/online-university-certificates/the-regulatory-affairs-certificate-medical-dev-1",
    exact: true, name: "Certificate Dual",
    component: <Navigate to={`/products/regulatory-affairs-certificate-medical-devices${window.location.search}`} replace /> },
  { path: "/education/video", exact: true, name: "Event Video", component: <PrivateRoute><VideoPage /></PrivateRoute> },
  { path: "/education/active-events", exact: true, name: "Active Events", component: ( <PrivateRoute><ActiveEvents /></PrivateRoute>) },
  { path: "/education/partnership", exact: true, name: "Partnership", component: <PartnerShip /> },
  { path: "/education/corporate-training", exact: true, name: "Corporate Training", component: <CorporateTraining /> },
  { path: "/education/in-person/workshop-proposal-request", exact: true, name: "Workshop Proposal Request", component: <WorkshopProposalRequest /> },
  { path: "/education/submit-idea", exact: true, name: "Submit Idea", component: <SubmitIdea /> },
  { path: "/education/event-registration-cancellation-request", exact: true, name: "Event Transfer and/or Cancellation Request", component: <EventRegistrationCancellationRequest /> },
  { path: "/education/e-learning/certificate-in-effective-communication", exact: true, name: "RAPS Certificate in Effective Communication", component: <CertificateInEffectiveCommunication /> },
  { path: "/education/european-events", exact: true, name: "European Events", component: <EuropeanEvents /> },
  { path: "/euevents", exact: true, name: "", component: <Navigate to={`/education/european-events${window.location.search}`} replace /> },
  { path: "/education/executive-development/page-tabs", exact: true, name: "Page Tabs", component: <PageTabs /> },
  { path: "/education/executive-development", exact: true, name: "Executive Development", component: <ExecutiveDevelopment /> },
  { path: "/education/ecornell-regulatory-leadership-certificate-program", exact: true, name: "eCornell Executive Leadership Certificate", component: <EcornellLeadershipCertificate /> },
  { path: "/education/all-products", exact: true, name: "Discover Products", component: <DiscoverProducts /> },
  { path: "/education/courses", exact: true, name: "Courses", component: <Courses /> },
  { path: "/education/courses/:tabName", exact: true, name: "Courses", component: <Courses /> },
  { path: "/education/workshop-week", exact: true, name: "Workshop Week", component: <WorkshopWeek /> }, { path: "/workshop-week", exact: true, name: "", component: <WorkshopWeek /> },
  { path: "/workshop-week", exact: true, name: "", component: <WorkshopWeek /> },
  { path: "/education/books", exact: true, name: "Books", component: <Books /> },
  { path: "/education/books/:tabName", exact: true, name: "Books tabs", component: <Books /> },
  { path: "/education/online-university", exact: true, name: "Online University", component: <OnlineUniversity /> },
  { path: "/education/online-university/:tabName", exact: true, name: "Online University tabs", component: <OnlineUniversity /> },
  { path: "/online-university", exact: true, name: "Online University tabs", component: <Navigate to={`/education/online-university${window?.location?.search || ""}`} replace /> },
  { path: "/education/sale", exact: true, name: "Black Friday Sale", component: <BlackFriday /> },
  { path: "/education/sale/:tabName", exact: true, name: "Black Friday Sale tabs", component: <BlackFriday /> },
  { path: "/education/summer-sale", exact: true, name: "Black Friday Sale", component: <BlackFriday /> },
  { path: "/education/summer-sale/:tabName", exact: true, name: "Black Friday Sale tabs", component: <BlackFriday /> },
  { path: "/education/preview", exact: true, name: "Preview", component: <ContentPreview /> }, 
  { path: "/education/cvent-registration", exact: true, name: "Cvent Registration", component: <PrivateRoute><CventRegistration /></PrivateRoute> }, 
  { path: "/education/conference-series", exact: true, name: "Conference Series", component: <ConferenceSeries /> },
  { path: "/conference-series", exact: true, name: "Conference Series", component: <ConferenceSeries /> },
  { path: "/education/exhibitor-sponsorships", exact: true, name: "Exhibitor Sponsorships", component: <ExhibitorSponsorships /> },
  { path: "/exhibitor-sponsorships", exact: true, name: "Exhibitor Sponsorships", component: <ExhibitorSponsorships /> },
  { path: "/education/event-code-of-conduct-incident-report", exact: true, name: "Events Code Of Conduct Incident Report", component: <EventsCodeOfConductIncidentReport /> },
  { path: "/resources/topic/:pageName", exact: true, name: "Topical Pages", component: <TopicalSubPage /> },

  // Website
  { path: "/website/terms-of-use", exact: true, name: "Terms of Use", component: <TermsOfUse /> },
  { path: "/website/style-guide", exact: true, name: "Style Guide", component: <StyleGuide /> },
  { path: "/website/privacy-policy", exact: true, name: "Privacy Policy", component: <PrivacyPolicy /> },
  { path: "/website/website-faqs/*", exact: true, name: "Website FAQs Page", component: <WebSiteFAQsPage /> },
  { path: "/website/virtual-event-help", exact: true, name: "Virtual Event Help", component: <VirtualEventHelp /> },

  // Alternate Urls
  { path: "/RAPSEvents", exact: true, name: "", component: <Navigate to={`/events${window.location.search}`} replace /> },
  { path: "/calendar", exact: true, name: "", component: <Navigate to={`/events${window.location.search}`} replace /> },
  { path: "/education/events", exact: true, name: "", component: <Navigate to={`/events${window.location.search}`} replace /> },
  { path: "/education/course-catalog", exact: true, name: "", component: <Navigate to={`/education/courses${window.location.search}`} replace /> },
  { path: "/education-events/executive-leadership-program", exact: true, name: "", component: <ExecutiveDevelopment /> },
  { path: "/scope-of-practice", exact: true, name: "", component: <Navigate to={`/resources/scope-of-practice-survey${window.location.search}`} replace /> },
  { path: "/terms-of-use", exact: true, name: "", component: <Navigate to={`/website/terms-of-use${window.location.search}`} replace /> },
  { path: "/enterprise", exact: true, name: "", component: <Navigate to={`/membership/enterprise-members${window.location.search}`} replace /> },
  { path: "/chapters", exact: true, name: "", component: <Navigate to={`/membership/chapters-local-networks${window.location.search}`} replace /> },
  { path: "/fellows", exact: true, name: "", component: <Navigate to={`/membership/raps-fellows${window.location.search}`} replace /> },
  { path: "/membership/raps-fellows/meet-raps-fellows", exact: true, name: "", component: <Navigate to={`/fellows/meet-raps-fellows${window.location.search}`} replace /> },
  { path: "/membership/onboarding", exact: true, name: "", component: <Navigate to={`/welcometoraps${window.location.search}`} replace /> },
  { path: "/membership/power-of-one-90-day-challenge", exact: true, name: "", component: <Navigate to={`/membership/power-of-one${window.location.search}`} replace /> },
  { path: "/website/emerging-markets-countries", exact: true, name: "Login", component: <EmergingMarketsCountries /> },
  { path: "/rftoday", exact: true, name: "", component: <Navigate to={`/news-and-articles${window.location.search}`} replace /> },
  { path: "/about", exact: true, name: "", component: <Navigate to={`/who-we-are${window.location.search}`} replace /> },
  { path: "/about-raps", exact: true, name: "", component: <Navigate to={`/who-we-are${window.location.search}`} replace /> },
  { path: "/celebrate-the-profession", exact: true, name: "", component: <Navigate to={`/who-we-are/celebrate-the-profession${window.location.search}`} replace /> },
  { path: "/contact", exact: true, name: "", component: <Navigate to={`/who-we-are/contact-us${window.location.search}`} replace /> },
  { path: "/about/board-of-directors", exact: true, name: "", component: <Navigate to={`/who-we-are/board-of-directors${window.location.search}`} replace /> },
  { path: "/who-we-are/governance", exact: true, name: "", component: <Navigate to={`/who-we-are/board-of-directors${window.location.search}`} replace /> },
  { path: "/who-we-are/governance/raps-board-of-directors", exact: true, name: "", component: <Navigate to={`/who-we-are/board-of-directors/raps-board-of-directors${window.location.search}`} replace /> },
  { path: "/who-we-are/governance/board-of-directors-position-descriptions-and-dutie", exact: true, name: "", component: <Navigate to={`/who-we-are/board-of-directors/board-of-directors-position-descriptions-and-dutie${window.location.search}`} replace /> },
  { path: "/who-we-are/governance/nomination-process", exact: true, name: "", component: <Navigate to={`/who-we-are/board-of-directors/nomination-process${window.location.search}`} replace /> },
  { path: "/who-we-are/raps-europe/raps-european-council", exact: true, name: "", component: <Navigate to={`/who-we-are/raps-europe/rec${window.location.search}`} replace /> },
  { path: "/europe", exact: true, name: "", component: <Navigate to={`/who-we-are/raps-europe${window.location.search}`} replace /> },
  { path: "/pressreleases", exact: true, name: "", component: <Navigate to={`/who-we-are/press-room${window.location.search}`} replace /> },
  { path: "/yir", exact: true, name: "", component: <Navigate to={`/who-we-are/year-in-review${window.location.search}`} replace /> },
  { path: "/advertising", exact: true, name: "", component: <ExternalRedirectUrl to="https://www.raps-mediaplanner.com/rapsmediaplanneraccess" /> },
  { path: "/myraps", exact: true, name: "", component: <Navigate to={`/member-dashboard${window.location.search}`} replace /> },
  { path: "/renewal", exact: true, name: "", component: <Navigate to={`/renew-membership${window.location.search}`} replace /> },
  { path: "/renew", exact: true, name: "", component: <Navigate to={`/renew-membership${window.location.search}`} replace /> },
  { path: "/regulatory-affairs-certification-rac-exam-passers-news-info-autumn-2022", exact: true, name: "", component: <Navigate to={`/news-and-articles/news-articles/2023/1/regulatory-affairs-certification-rac-exam-passers${window.location.search}`} replace /> },
  { path: '/rac', exact: true, name: 'Certification RAC', component: <Navigate to={`/certifications/rac${window?.location?.search || ""}`} replace /> },
  { path: '/rcc', exact: true, name: 'Certification RCC', component: <Navigate to={`/certifications/rcc${window?.location?.search || ""}`} replace /> },

  // End of Alternate Urls
];
export default AppRouter;
